import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          The Gadfly
        </p>
        <a
          className="App-link"
          href="mailto:rizkjihad@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact
        </a>
        <meta name="google-site-verification" content="gjbLQineNE7a4rGAm_He11J48y_IpFG2JVBv7iGc4YI" />
      </header>
    </div>
  );
}

export default App;
